<template>
    <div>
        <div :class="current_item_is_selected ? 'text-dark' : ''" class="mt-auto ps-2 pe-2 font-size-10" >
            <div class="mb-1" v-if="item.owner_name">
                <span class="grey-date-tag">
                    <font-awesome-icon class="fa-fw" icon="fa-user"></font-awesome-icon>
                    {{ item.owner_name }}
                </span>
            </div>
            <div class="mb-1">
                <span class="grey-date-tag">
                    <font-awesome-icon class="fa-fw" icon="fa-calendar-check"></font-awesome-icon>
                    {{ getDateFormat }}
                </span>
            </div>
            <div class="mb-1">
                <span class="grey-date-tag me-2">
                    Target: 
                    {{ item.target }}
                </span>
                <span class="grey-date-tag me-2">
                    {{ itemUnitType }}
                </span>
                <span class="text-capitalize grey-date-tag me-2">
                    {{ item.current_time_period }}
                </span>
            </div>
        </div>
        <div class="mb-1 mt-0"/>
    </div>
</template>

<script>
import { activityComputed } from '@/state/helpers'
import dateMixin from '@/mixins/dateMixin.js'
export default {
    mixins: [dateMixin],
    data:() => ({}),
    props:{
        item:{
            required: true,
        },
        current_item_is_selected:{
            default: false,
        }
    },
    computed:{
        ...activityComputed,
        itemUnitType(){
            if(!this.keyResultUnit){
                return;
            }
            let type = this.keyResultUnit.find( unit => {
                return unit.id == this.item.unit_type_id;
            });
            if(type){
                return type.name;
            }
            else{
                return 'not found';
            }
        },
        getDateFormat(){
            let start = this.getSimpleDate(this.item.start_date, '/');
            //! do some hack to get the end date.. some items have deadline, some items have end_date
            let end = this.item.deadline ? this.item.deadline : this.item.end_date
            end = this.getSimpleDate(end, '/');
            return `${start} - ${end}`
        },
    },
    methods:{},
    mounted(){
        console.log('ITEM', this.item);
    },
}
</script>

<style>

</style>